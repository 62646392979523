import { FRONT_END_BET_STATUS } from '~/utils/constants'

// State
export const state = () => ({
    loading: false,
    players: [],
    onlinePlayers: { data: [] },
    playerGameLogs: {
        data: [],
    },
    player: {},
    messages: { data: [] },
    betHistory: {
        total: null,
        totalPages: null,
        data: [],
    },
    sameIp: [],
    sameIpPlayer: [],
    fingerPrint: [],
    createPlayer: {},
    playerBankAccounts: [],
    playerBankAccount: {},
    changePassword: {},
    playerBankAccountName: null,
    transferBalanceLogs: [],
    transactionLogs: { data: [] },
    loginAndIpLogs: [],
    ipLogs: [],
    betDetail: {},
    heatmap: [],
    heatmapConfig: {
        group: [[], [], [], []],
    },
    numberOfInvited: {},

    // cancel token
    allPlayerProfileCancelToken: {},
})

// Actions
export const actions = {
    // Get all players
    async players({ commit, state }, params) {
        const data = JSON.parse(JSON.stringify(params))
        if (data.player_bank_account_number) {
            data.bank_account_number = data.player_bank_account_number
            delete data.player_bank_account_number
        }

        commit('setAllPlayerProfileCancelToken', {
            players: this.$axios.CancelToken.source(),
        })
        if (data.agent_uuid === true || data.agent_uuid === 'true') {
            delete data.agent_uuid
            data.is_main_operator = true
        }
        try {
            commit('loading', true)
            await this.$axios
                .get(`players.playerList`, {
                    params: data,
                    cancelToken:
                        state.allPlayerProfileCancelToken.players.token,
                })
                .then((response) => {
                    commit('setPlayers', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    // Get all online players
    async onlinePlayers({ commit, state }, data) {
        try {
            commit('loading', true)
            commit('setAllPlayerProfileCancelToken', {
                onlinePlayer: this.$axios.CancelToken.source(),
            })
            await this.$axios
                .get(`/player-management/players/online`, {
                    params: data,
                    cancelToken:
                        state.allPlayerProfileCancelToken.onlinePlayer.token,
                })
                .then((response) => {
                    commit('setOnlinePlayers', response.data)
                    commit('loading', false)
                })
        } catch (error) {
            commit('loading', false)
            return false
        }
    },
    async logoutOnlinePlayer({ rootGetters }, playerId) {
        await this.$axios
            .put(`/player-management/players/online/kick-out/${playerId}`)
            .then(() => {})
            .catch(() => false)
    },
    // Get single player
    async player({ commit, state }, playerId) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            profile: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/players/profile/${playerId}`, {
                cancelToken: state.allPlayerProfileCancelToken.profile.token,
            })
            .then((response) => {
                commit('setPlayer', response.data)
                commit('loading', false)
            })
            .catch(() => false)
    },

    // ? Messages
    // Get All
    async messages({ commit, state }, params) {
        if (params?.currency === 'ALL') delete params.currency
        commit('setAllPlayerProfileCancelToken', {
            messages: this.$axios.CancelToken.source(),
        })
        commit('loading', true)
        await this.$axios
            .get('/player-messages', {
                params,
                cancelToken: state.allPlayerProfileCancelToken.messages.token,
            })
            .then((response) => {
                commit('setMessages', response.data)
                commit('loading', false)
            })
            .catch(() => {
                commit('loading', false)
                return false
            })
    },

    // Send message
    sendMessage({ commit, rootGetters }, form) {
        commit('loading', true)
        this.$axios
            .post('/player-messages', form)
            .then(() => {
                this.$router.push('/players/messages')
                commit('loading', false)
            })
            .catch(() => {
                commit('loading', false)
                return false
            })
    },

    // deleteMessage
    async deleteMessage({ rootGetters }, id) {
        await this.$axios
            .delete(`/player-messages/${id}`)
            .then(() => {})
            .catch(() => false)
    },

    // ? Bet history
    // Bet history - Get All
    async betHistory({ commit, state }, data) {
        commit('setAllPlayerProfileCancelToken', {
            betHistory: this.$axios.CancelToken.source(),
        })
        const params = { ...data }
        if (!data.sort && data.bet_history_view === 1) {
            params.sort = 'deduct_time|desc'
        } else if (!data.sort && data.bet_history_view === 2) {
            params.sort = 'deduct_time|desc'
        }
        if (
            params &&
            params.game_type_uuid &&
            params.game_type_uuid.length > 0
        ) {
            params.game_type_uuid = JSON.stringify(params.game_type_uuid)
        } else delete params.game_type_uuid
        if (
            params &&
            params.game_provider_uuid &&
            params.game_provider_uuid.length > 0
        ) {
            params.game_provider_uuid = JSON.stringify(
                params.game_provider_uuid
            )
        } else delete params.game_provider_uuid

        // Detail view = 2, normal view = 1 (Backend Need this logic) for bet_status
        if (data?.bet_history_view === 2) {
            params.action_status = params.bet_status
            delete params.bet_status
        } else {
            delete params?.action_status
        }

        // To identify the cancel status (Cancel By Admin && Cancel By Provider)
        if (data.bet_status === FRONT_END_BET_STATUS.cancelByAdmin) {
            params.bet_status = 3
            params.is_admin_canceled = true
        } else if (data.bet_status === FRONT_END_BET_STATUS.cancelByProvider) {
            params.bet_status = 3
            params.is_admin_canceled = false
        }

        commit('loading', true)
        await this.$axios
            .get('/bet-management/bet-history', {
                params,
                cancelToken: state.allPlayerProfileCancelToken.betHistory.token,
            })
            .then((response) => {
                // To put the unique key for table :key and :id
                const betHistory = response.data
                if (betHistory.data) {
                    betHistory.data.forEach((el, index) => {
                        el._key = el.id + '-' + index
                    })
                }
                commit('betHistory', {
                    ...betHistory,
                    bet_history_view: params.bet_history_view,
                })
                commit('loading', false)
            })
            .catch(() => false)
            .finally(() => commit('loading', false))
    },
    sameIp({ commit, state }, params) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            sameIp: this.$axios.CancelToken.source(),
        })
        this.$axios
            .get(`/statistics/investigate/same-ip/${params}`, {
                cancelToken: state.allPlayerProfileCancelToken.sameIp.token,
            })
            .then((response) => {
                commit('sameIp', response.data)
                commit('loading', false)
            })
            .catch((e) => commit('loading', false))
    },
    sameIpWithPlayerId({ commit, state }, params) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            sameIp: this.$axios.CancelToken.source(),
        })
        this.$axios
            .get(`/statistics/investigate/same-ip/player-id/${params}`, {
                cancelToken: state.allPlayerProfileCancelToken.sameIp.token,
            })
            .then((response) => {
                commit('sameIp', response.data)
                commit('loading', false)
            })
            .catch((e) => commit('loading', false))
    },
    fingerPrint({ commit, state }, params) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            fingerPrint: this.$axios.CancelToken.source(),
        })
        this.$axios
            .get(`/statistics/investigate/same-fingerprint/${params}`, {
                cancelToken:
                    state.allPlayerProfileCancelToken.fingerPrint.token,
            })
            .then((response) => {
                commit('fingerPrint', response.data)
                commit('loading', false)
            })
            .catch((e) => commit('loading', false))
    },

    sameIpPlayer({ commit }, params) {
        commit('loading', true)
        this.$axios
            .get(`/statistics/investigate/same-ip/player-id/${params}`)
            .then((response) => {
                commit('sameIpPlayer', response.data)
                commit('loading', false)
            })
            .catch((e) => commit('loading', false))
    },

    async createPlayer({ commit }, data) {
        commit('loading', true)
        const response = await this.$axios
            .post('/player-management/players', data)
            .then((response) => {
                commit('createPlayer', response.data)
                commit('loading', false)
                return true
            })
            .catch((e) => {
                commit('loading', false)
            })
        return response
    },
    async createPlayerWithCrypto({ commit }, data) {
        commit('loading', true)
        // API misspelled
        const response = await this.$axios
            .post('/players/crypto-currency', data)
            .then((response) => {
                commit('createPlayer', response.data)
                commit('loading', false)
                return true
            })
            .catch((e) => {
                commit('loading', false)
            })
        return response
    },
    playerBankAccounts({ commit, state }, params) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            bankAccount: this.$axios.CancelToken.source(),
        })
        this.$axios
            .get(`/player-management/players/bank-account/${params}`, {
                cancelToken:
                    state.allPlayerProfileCancelToken.bankAccount.token,
            })
            .then((response) => {
                commit('playerBankAccounts', response.data.player_bank_account)
                commit(
                    'setPlayerBankAccount',
                    response.data.account_holder_name
                )
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    playerBankAccount({ commit }, params) {
        commit('loading', true)
        this.$axios
            .get(`/player-management/players/bank-account/id/${params}`)
            .then((response) => {
                commit('playerBankAccount', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async playerCreateBankAccount({ commit, rootGetters }, data) {
        const playerId = data.player_id
        delete data.player_id
        const response = await this.$axios
            .post(`/player-management/players/bank-account/${playerId}`, data)
            .then((response) => {
                return true
            })
            .catch((e) => {
                return false
            })
        return response
    },
    async playerUpdateBankAccount({ commit, rootGetters }, data) {
        const id = data.id
        delete data.id
        const response = await this.$axios
            .put(`/player-management/players/bank-account/${id}`, data)
            .then((response) => {
                if (typeof response.data.message === 'string') {
                    this.$toast.success(
                        rootGetters['master/storeTl'](response.data.message)
                    )
                } else {
                    this.$toast.success(response.data.message)
                }
                return true
            })
            .catch((e) => {
                return false
            })
        return response
    },
    async playerDeleteBankAccount({ commit, rootGetters }, id) {
        commit('loading', true)
        await this.$axios
            .delete(`/player-management/players/bank-account/${id}`)
            .then((response) => {
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async playerChangePassword({ commit }, id) {
        commit('loading', true)
        await this.$axios
            .put(`/player-management/players/password/random/${id}`)
            .then((response) => {
                commit('changePassword', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async playerGameLogs({ commit, state }, params) {
        commit('loading', true)
        // state?.allPlayerProfileCancelToken?.playerGameLogs?.cancel('')
        commit('setAllPlayerProfileCancelToken', {
            playerGameLogs: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/player-game-log`, {
                params,
                cancelToken:
                    state.allPlayerProfileCancelToken.playerGameLogs.token,
            })
            .then((response) => {
                commit('playerGameLogs', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async transferBalanceLogs({ commit, state }, params) {
        commit('loading', true)
        const playerId = params.player_id
        delete params.player_id
        commit('setAllPlayerProfileCancelToken', {
            transferBalanceLogs: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/players/transfer-log/${playerId}`, {
                params,
                cancelToken:
                    state.allPlayerProfileCancelToken.transferBalanceLogs.token,
            })
            .then((response) => {
                commit('transferBalanceLogs', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async transactionLogs({ commit, state }, params) {
        commit('loading', true)
        if (params?.sort) delete params.sort
        commit('setAllPlayerProfileCancelToken', {
            transaction: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/transactions`, {
                cancelToken:
                    state.allPlayerProfileCancelToken.transaction.token,
                params,
            })
            .then((response) => {
                commit('transactionLogs', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async updatePlayer({ rootGetters }, form) {
        const id = form.id
        delete form.id
        const response = await this.$axios
            .put(`/player-management/players/${id}`, form)
            .then((_) => {
                return true
            })
            .catch((e) => {
                return false
            })
        return response
    },
    async updatePlayerSocial({ commit }, form) {
        const id = form.id
        delete form.id
        const response = await this.$axios
            .put(`/player-management/players/social/${id}`, form)
            .then((_) => {
                return true
            })
            .catch((e) => {
                return false
            })
        return response
    },
    async loginAndIpLogs({ commit, state }, params) {
        commit('loading', true)
        commit('setAllPlayerProfileCancelToken', {
            loginAndIpLogs: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/players/logs/login`, {
                params,
                cancelToken:
                    state.allPlayerProfileCancelToken.loginAndIpLogs.token,
            })
            .then((response) => {
                commit('loginAndIpLogs', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async ipLogs({ commit, state }, params) {
        commit('setAllPlayerProfileCancelToken', {
            ipLogs: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/players/logs/login`, {
                params,
                cancelToken: state.allPlayerProfileCancelToken.ipLogs.token,
            })
            .then((response) => {
                commit('setIpLogs', response.data)
            })
            .catch((e) => {})
    },
    async betDetail({ commit }, id) {
        commit('setBetDetail', {})
        await this.$axios
            .get(`/bet-management/bet-detail/${id}`)
            .then((response) => {
                commit('setBetDetail', response.data)
            })
            .catch((e) => {})
    },
    async updateTurnoverLogs({ commit }, form) {
        commit('loading', true)
        const id = form.id
        delete form.id
        return await this.$axios
            .put(`/player-management/transactions/players/${id}`, form)
            .then((response) => {
                commit('loading', false)
                return true
            })
            .catch(() => {
                commit('loading', false)
                return false
            })
    },
    async heatmap({ commit, state }, data) {
        commit('loading', true)
        if (!data.month) return
        if (!data.year) return
        if (!data.player_id) return

        commit('setAllPlayerProfileCancelToken', {
            heatmap: this.$axios.CancelToken.source(),
        })

        await this.$axios
            .get(
                `/player-management/players/heat-map/${data.year}/${data.month}/${data.player_id}`,
                {
                    cancelToken:
                        state.allPlayerProfileCancelToken.heatmap.token,
                }
            )
            .then((response) => {
                commit('heatmap', response.data)
                commit('loading', false)
            })
            .catch((e) => {
                commit('loading', false)
            })
    },
    async heatmapConfig({ commit, state }) {
        commit('setAllPlayerProfileCancelToken', {
            configuration: this.$axios.CancelToken.source(),
        })
        await this.$axios
            .get(`/player-management/players/heat-map/configuration`, {
                cancelToken:
                    state.allPlayerProfileCancelToken.configuration.token,
            })
            .then((response) => {
                commit('heatmapConfig', response.data)
            })
            .catch((e) => {})
    },
    async numberOfInvited({ commit }, params) {
        const playerId = params.player_id
        delete params.player_id
        await this.$axios
            .get(`/player-management/players/refer-friend/${playerId}`, {
                params,
            })
            .then((response) => {
                commit('numberOfInvited', response.data)
            })
            .catch((e) => {})
    },
    async triggerTurnover({ commit }, playerId) {
        const response = await this.$axios
            .put(`/player-management/players/trigger-turnover/${playerId}`)
            .then((response) => {
                return true
            })
            .catch((e) => {
                return false
            })
        return response
    },
    // deleteMessage
    async deleteSocialId({ rootGetters }, id) {
        await this.$axios
            .delete(`/player-management/players/social/${id}`)
            .then(() => {})
            .catch(() => false)
    },
}

// Mutations
export const mutations = {
    loading(state, type) {
        state.loading = type
    },
    setPlayers(state, data) {
        state.players = data
    },
    setOnlinePlayers(state, data) {
        state.onlinePlayers = data
    },
    setMessages(state, data) {
        state.messages = data
    },
    setPlayer(state, data) {
        state.player = data
    },
    betHistory: (state, data) => {
        state.betHistory = data
    },
    sameIp: (state, data) => {
        state.sameIp = data
    },
    fingerPrint: (state, data) => {
        state.fingerPrint = data
    },
    sameIpPlayer: (state, data) => {
        state.sameIpPlayer = data
    },
    createPlayer: (state, data) => {
        state.createPlayer = data
    },
    playerBankAccounts: (state, data) => {
        // sort is_primary bank account equal to true first then false
        const newSort = data.sort(
            (a, b) => Number(b.is_primary) - Number(a.is_primary)
        )
        state.playerBankAccounts = newSort
    },
    playerBankAccount: (state, data) => {
        state.playerBankAccount = data
    },
    changePassword: (state, data) => {
        state.changePassword = data
    },
    setPlayerBankAccount: (state, data) => {
        state.playerBankAccountName = data
    },
    playerGameLogs: (state, data) => {
        state.playerGameLogs = data
    },
    transferBalanceLogs: (state, data) => {
        state.transferBalanceLogs = data
    },
    transactionLogs: (state, data) => {
        state.transactionLogs = data
    },
    loginAndIpLogs: (state, data) => {
        state.loginAndIpLogs = data
    },
    setIpLogs: (state, data) => {
        state.ipLogs = data
    },
    setBetDetail: (state, data) => {
        state.betDetail = data
    },
    heatmap: (state, data) => {
        state.heatmap = data
    },
    heatmapConfig: (state, data) => {
        state.heatmapConfig = data
    },
    numberOfInvited: (state, data) => {
        state.numberOfInvited = data
    },
    setAllPlayerProfileCancelToken: (state, data) => {
        const oldData = { ...state.allPlayerProfileCancelToken }
        state.allPlayerProfileCancelToken = {
            ...oldData,
            ...data,
        }
    },
}
